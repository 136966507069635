import React from 'react'
import qs from 'query-string'

import Layout from '../components/layout'
// import { graphql, Link } from 'gatsby'

// import GatsbyImage, { FixedObject } from 'gatsby-image'
import Header from '../components/header'

import { IndexQuery } from '../generated/graphql'
import Footer from '../components/Footer'
// import PoliticaPrivacidade from '../components/PoliticaPrivacidade'
// import Termos from '../components/Termos'
interface IndexProps {
	data: IndexQuery
}

const URL = `${process.env.GATSBY_IFRAME_PROTOCOL}://${process.env.GATSBY_IFRAME_URL}`

const IndexPage: React.FC<IndexProps> = () => {
	let uuid = ''
	if (typeof window !== 'undefined') {
		const query = qs.parse(window.location.search)
		console.log(query)
		uuid = query.uuid as string
	}

	return (
		<Layout>
			<div className='grid grid-rows-index-mobile lg:grid-rows-index grid-cols-1 w-full min-h-screen'>
				<Header />
				<iframe
					id='iframe-assinatura-digital'
					className='w-full h-iframe-mobile lg:h-full'
					frameBorder='0'
					title='Iframe de Assinatura Digital'
					src={`${URL}/visualiza/${uuid}`}
					allowFullScreen
				/>
			</div>
			{/* <Footer /> */}
		</Layout>
	)
}
export default IndexPage
